import React from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AStyledLink, AStyledPrimaryLink } from './Link';

const CTA = styled.div``;

const Overlay = styled.div`
  position: relative;
  margin-top: 24px;
  ${CTA} {
    display: none;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    ${CTA} {
      display: flex;
    }
  }
`;

export const ProductImage = ({ fixed, alt, affiliateUrl }) => {
  return (
    <>
      <Overlay>
        <CTA>
          <AStyledLink href={affiliateUrl} rel="nofollow" target="_blank">
            Se flere bilder
          </AStyledLink>
        </CTA>
        <GatsbyImage image={getImage(fixed)} alt={alt} />
      </Overlay>
    </>
  );
};
