/**
 * Creates a url for a adrecord merchant
 *
 * @param {string | number} channel - channel id
 * @param {string | number} program - program / merchant id
 * @param {string } productUrl - Url to the product to promote
 * @param {string=} epi - custom string used for A/B testing etc
 * @param {string=} uid2 - custom string2
 *
 * @returns {string} - a url used as a href attribute
 */
const makePartnerAdsUrl = (channel, program, productUrl, epi, uid2) => {
  if (!channel || !program || !productUrl) {
    throw new Error('Missing input');
  }
  const base = 'https://www.partner-ads.com/no/klikbanner.php';
  const channelString = `partnerid=${channel}`;
  const programString = `&bannerid=${program}`;
  const epiString = epi ? `&uid=${epi}` : '';
  const productString = `&htmlurl=${productUrl}`;
  const uid2String = `&uid2=www.testavisen.no${
    uid2 ? `${encodeURIComponent(uid2)}` : ''
  }`;
  return `${base}?${channelString}${programString}${epiString}${uid2String}${productString}`;
};

export { makePartnerAdsUrl };
