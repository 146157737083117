import React, { useContext } from 'react';
import { AffiliatePrograms } from '../utils/affiliateUrl/types';

const AffiliateProgramsContext = React.createContext<AffiliatePrograms>([]);

export const AffiliateProgramsProvider: React.FC<{
  affiliatePrograms: AffiliatePrograms;
}> = ({ affiliatePrograms, children }) => (
  <AffiliateProgramsContext.Provider value={affiliatePrograms}>
    {children}
  </AffiliateProgramsContext.Provider>
);

export const useAffiliateProgramsContext = (): AffiliatePrograms => {
  return useContext(AffiliateProgramsContext);
};
