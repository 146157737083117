// interface AffiliateInfo {
//   affiliateProgram: { name: string; affiliateId: string };
//   merchantIdentifier: string | undefined;
// }

import { AffiliatePrograms } from './types';

const cleanHostName = (url: string): string => {
  // extract hostname from url
  const { hostname } = new URL(url);
  // remove www
  const hostWithoutWww = hostname.includes('www')
    ? hostname.substr(4, hostname.length)
    : hostname;

  return hostWithoutWww;
};

// skriv tæstar
export const findAffiliateInfoForUrl = ({
  url,
  affiliates,
}: {
  url: string;
  affiliates: AffiliatePrograms;
}): any => {
  const hostWithoutWww = cleanHostName(url);
  // find merchant for url
  try {
    const merchantHit: any = affiliates.filter(aff => {
      return aff.merchants.find(
        merchant => merchant.merchantUrl === hostWithoutWww,
      );
    });
    const merchant = merchantHit[0].merchants.find(
      m => m.merchantUrl === hostWithoutWww,
    );
    const { merchantIdentifier, customDomain } = merchant;

    return {
      affiliateProgram: {
        name: merchantHit[0].name,
        affiliateId: merchantHit[0].affiliateId,
      },
      merchantIdentifier,
      customDomain,
    };
  } catch (error) {
    return undefined;
  }
};

// export const findAffiliateInfoForUrl = (
//   url: string,
//   affiliates: AffiliatePrograms
// ): any => {
//   const hostWithoutWww = cleanHostName(url);
//   // find merchant for url
//   const findMerchant = affiliates.map((aff) => {
//     const maybeMerchant = aff.merchants.find(
//       (merchant) => merchant.merchantUrl === hostWithoutWww
//     );
//     return {
//       affiliateProgram: { name: aff.name, affiliateId: aff.affiliateId },
//       merchantIdentifier: maybeMerchant
//         ? maybeMerchant.merchantIdentifier
//         : undefined,
//     };
//   })[0];

//   return findMerchant;
// };
