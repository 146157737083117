import React from 'react';
import { useAffiliateProgramsContext } from '../../context/AffiliateProgramsContext';
import makeAffiliateUrl from '../../utils/affiliateUrl/makeAffiliateUrl';
import { globalHistory } from '@reach/router';

export const OutboundLink: React.FC<{ anchor: string; href: string }> = ({
  anchor,
  href,
}) => {
  const affiliatePrograms = useAffiliateProgramsContext();
  const path = globalHistory.location.pathname;

  return (
    <a
      rel="nofollow"
      href={makeAffiliateUrl(href, affiliatePrograms, 'lenke-i-innhold', path)}
    >
      {anchor}
    </a>
  );
};
