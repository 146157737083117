import { makeAdrecordUrl } from './adrecord';
import { makeAdtractionUrl } from './adtraction';
import { makePartnerAdsUrl } from './partner-ads';
import { makeTradetrackerUrl } from './tradetracker';
import { makeTradedoublerUrl } from './tradedoubler';
import { makeAwinUrl } from './awin';
import { findAffiliateInfoForUrl } from './findAffiliateForUrl';
import { AffiliatePrograms } from './types';
import { makeDaisyconUrl } from './daisycon';
import { makeTargetCircleUrl } from './targetcircle';
import { getWCTUrl } from '../getWCTUrl';

// `https://track.adtraction.com/t/t?
// a=1035643985
// &as=1522577889
// &t=2&tk=1&
// epi=BOSU%C2%AE%20Balance%20Trainer%20Elite&
// url=https://www.gymgrossisten.no/bosu-balance-trainer-elite/2005.html F`;

// `https://track.adtraction.com/t/t?
// as=1522577889
// &a=1301793022
// &t=2&tk=1&
// epi=BOSU® Balance Trainer Elite&
// url=https://www.gymgrossisten.no/bosu-balance-trainer-elite/2005.html`;

const makeAffiliateUrl = (
  url: string,
  affiliates: AffiliatePrograms,
  customString?: string,
  pathname?: string,
): string => {
  if (!url) {
    return '';
  }
  const affiliateInfo = findAffiliateInfoForUrl({ url, affiliates });

  if (
    !affiliateInfo ||
    !affiliateInfo.affiliateProgram ||
    !affiliateInfo.affiliateProgram.name ||
    !affiliateInfo.affiliateProgram.affiliateId ||
    !affiliateInfo.merchantIdentifier
  ) {
    return url;
  }

  const {
    affiliateProgram: { name, affiliateId },
    merchantIdentifier,
    customDomain,
  } = affiliateInfo;

  let affiliateUrl = '';
  switch (name.toLowerCase()) {
    case 'adtraction':
      affiliateUrl = makeAdtractionUrl({
        channel: affiliateId,
        program: merchantIdentifier,
        productUrl: url,
        epi: customString,
        customDomain,
      });
      break;
    case 'adrecord':
      affiliateUrl = makeAdrecordUrl(
        affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'tradetracker':
      affiliateUrl = makeTradetrackerUrl(
        affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'tradedoubler':
      affiliateUrl = makeTradedoublerUrl(
        affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'awin':
      affiliateUrl = makeAwinUrl(
        affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'partner-ads':
    case 'partner ads':
      affiliateUrl = makePartnerAdsUrl(
        affiliateId,
        merchantIdentifier,
        url,
        customString,
        pathname,
      );
      break;
    case 'targetcircle':
      affiliateUrl = makeTargetCircleUrl(
        affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'daisycon':
      affiliateUrl = makeDaisyconUrl(url, customDomain, customString || '');
      break;
    default:
      break;
  }
  const wctAffiliateUrl = getWCTUrl(affiliateUrl);
  return wctAffiliateUrl;
};

export default makeAffiliateUrl;
