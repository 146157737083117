/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { CssBaseline, Paper, Container } from '@mui/material';
import EvolvedMenu from './Menu/EvolvedMenu';
import { Disclosure } from './Disclosure';

const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f0f2f5;
`;

const ContentContainer = styled(Container)`
  font-size: 1rem;
  flex: 1;
  padding: 12px;
`;

const ContentPaper = styled(Paper)`
  padding: 24px;
  margin: 12px;
`;

interface LayoutProps {
  children: ReactNode;
  menuItems: any;
}

const Layout: React.FC<LayoutProps> = ({ children, menuItems }) => {
  return (
    <SiteContainer>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap"
      />
      <CssBaseline />
      <EvolvedMenu menuItems={menuItems} />
      <ContentContainer maxWidth="md">
        <Disclosure />
        <ContentPaper>{children}</ContentPaper>
      </ContentContainer>
      {/* <Footer /> */}
    </SiteContainer>
  );
};

export default Layout;
