/* eslint-disable @typescript-eslint/no-explicit-any */
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import ContentfulProductReview from '../components/ContentfulProductReview';
import { customIdFromText } from './customIdFromJsx';
import { Heading2 } from '../components/common/Heading2';
import PageList from '../components/PageList';
import { renderAsset } from './renderAsset';
import SimpleTable from '../components/SimpleTable/SimpleTable';
import { OutboundLink } from '../components/common/OutboundLink';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const renderEmbeddedEntry: React.FC<any> = node => {
  const typeName = node?.data?.target?.__typename;

  switch (typeName) {
    case 'ContentfulProduktTabell': {
      const title: string | undefined = node.data.target?.title;
      const products =
        node.data.target?.productReferences.map(p => ({
          ...p,
          url: p.url.url,
        })) ?? [];
      return (
        <>
          {title ? (
            <Heading2 id={customIdFromText(title)}>{title}</Heading2>
          ) : null}
          <SimpleTable products={products} />
        </>
      );
    }
    case 'ContentfulPageList': {
      const pages = (node?.data?.target?.pageListItemRefs ?? []).map(
        ({
          shortName,
          pageReference,
        }: {
          shortName: string;
          pageReference: { slug: string; featureImage: any };
        }) => {
          return {
            shortName,
            slug: pageReference.slug,
            id: node?.data?.target?.contentful_id,
            updatedAt: node?.data?.target?.updatedAt,
            featureImage: pageReference?.featureImage,
          };
        },
      );
      return <PageList pages={pages} />;
    }
    case 'ContentfulProductReview': {
      const reviewText = node?.data?.target?.reviewText?.reviewText;
      return (
        <ContentfulProductReview
          url={node?.data?.target?.product?.url?.url}
          title={node?.data?.target?.title}
          productImage={node?.data?.target?.product?.productImage}
          productName={node?.data?.target?.product?.name}
        >
          {reviewText ? <ReactMarkdown>{reviewText}</ReactMarkdown> : null}
        </ContentfulProductReview>
      );
    }
    default:
      break;
  }
  // return null;
};

const renderEntryHyperlink = (node, children) => {
  if (!node || !node.data.target || !node.data.target.slug) return;
  return <Link to={`/${node.data.target.slug}/`}>{children}</Link>;
};

const renderH2: React.FC<any> = (node: { content: { value: any }[] }) => {
  const { value } = node.content[0];

  const customId = customIdFromText(value);
  return <Heading2 id={customId}>{value}</Heading2>;
};

const renderStandardLink: React.FC<any> = (node: {
  data: { uri: string };
  content: { value: string }[];
}) => {
  return <OutboundLink href={node.data.uri} anchor={node.content[0].value} />;
};

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
    [INLINES.ENTRY_HYPERLINK]: renderEntryHyperlink,
    [BLOCKS.HEADING_2]: renderH2,
    [BLOCKS.EMBEDDED_ASSET]: renderAsset,
    [INLINES.HYPERLINK]: renderStandardLink,
  },
};

export { options, renderEntryHyperlink };
