import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../utils/richTextOptions';

type ContentType = {
  richTextContent: any;
};

export const Content: React.FC<ContentType> = ({ richTextContent }) => {
  const content = renderRichText(richTextContent, options);
  return <main>{content}</main>;
};
