import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { MenuItem } from '../components/Menu/Types';
import { AffiliateProgramsProvider } from '../context/AffiliateProgramsContext';
import { Content } from '../components/Content';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface PageContext {
  richTextContent: any;
  metaTitle: string;
  metaDescription: string;
  featureImage?: any;
  mainMenuItems: [MenuItem];
  affiliatePrograms: any;
}

interface Props {
  pageContext: PageContext;
  data: any;
}

export default function Page({
  pageContext: {
    richTextContent,
    metaTitle,
    metaDescription,
    featureImage,
    mainMenuItems,
    affiliatePrograms,
  },
}: Props): JSX.Element {
  return (
    <Layout menuItems={mainMenuItems}>
      <SEO
        title={metaTitle}
        metaDescription={metaDescription}
        meta={[{ name: 'ir-site-verification-token', value: '1416510574' }]}
      />
      {featureImage ? (
        <GatsbyImage
          image={getImage(featureImage)}
          alt={featureImage.description}
        />
      ) : null}
      <AffiliateProgramsProvider affiliatePrograms={affiliatePrograms}>
        <Content richTextContent={richTextContent} />
      </AffiliateProgramsProvider>
    </Layout>
  );
}
