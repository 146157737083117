// https://track.adtraction.com/t/t?a=1157919242&as=1342585743&t=2&tk=1
// with custom domain: https://go.blivakker.no/t/t?a=1786465255&as=1620904481&t=2&tk=1&epi=epi&url=https://www.blivakker.no/product/harpleie/harpleie/accessories/accessories-til-har/stylingverktoy/varmeborste/3278771/babylisspro-air-styler-pro-blue-light
/**
 * Creates a url for a adrecord merchant
 *
 * @param {string | number | any} channel - channel id
 * @param {string | number | any} program - program / merchant id
 * @param {string } productUrl - Url to the product to promote
 * @param {string=} epi - custom string used for A/B testing etc
 *  @param {string=} customDomain - custom domain, defaults to 'https://track.adtraction.com/t/t'
 * @returns {string} - a url used as a href attribute
 */
const makeAdtractionUrl = ({
  channel,
  program,
  productUrl,
  epi,
  customDomain,
}) => {
  if (!channel || !program || !productUrl) {
    throw new Error('Missing input');
  }
  const base = customDomain ? customDomain : 'https://track.adtraction.com/t/t';
  const channelString = `&as=${channel}`;
  const programString = `a=${program}`;
  const epiString = epi ? `&epi=${epi}-{clickid}` : '&epi={clickid}';
  const productString = `&url=${productUrl}`;
  return `${base}?${programString}${channelString}&t=2&tk=1${epiString}${productString}`;
};

export { makeAdtractionUrl };
