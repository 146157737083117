// https://tc.tradetracker.net/?c=25751&m=12&a=353997&r=asd
// https://tc.tradetracker.net/?c=25751&m=12&a=353997&r=asd&u=%2Fproduktkategori%2Ftilbehor%2Fyogamatte-vask%2Falle-matter%2F
// https://tc.tradetracker.net/?c=30764&m=12&a=353997&r=asd&u=%2Fasdqweasdavcvbcb%2F

const tradetrackerUrlHelper = fullUrl => {
  const relativeUrl = fullUrl.replace(/^(?:\/\/|[^/]+)*\//, '');
  return encodeURIComponent(`/${relativeUrl}`);
};

/**
 * Creates a url for a adrecord merchant
 *
 * @param {string | number} channel - channel id
 * @param {string | number} program - program / merchant id
 * @param {string } productUrl - Url to the product to promote
 * @param {string=} epi - custom string used for A/B testing etc
 *
 * @returns {string} - a url used as a href attribute
 */
const makeTradetrackerUrl = (channel, program, productUrl, epi) => {
  if (!channel || !program || !productUrl) {
    throw new Error('Missing input');
  }

  const relativeEncodedProductUrl = tradetrackerUrlHelper(productUrl);

  const base = 'https://tc.tradetracker.net/';
  const channelString = `a=${channel}`;
  const programString = `&c=${program}`;
  const epiString = epi ? `&r=${epi}` : '';
  const productString = `&u=${relativeEncodedProductUrl}`;
  return `${base}?${channelString}${programString}&m=12${epiString}${productString}`;
};

export { makeTradetrackerUrl, tradetrackerUrlHelper };
