import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

export const renderAsset: React.FC<any> = node => {
  if (!node || !node.data || !node.data.target) return null;
  if (node.data.target?.file?.contentType.includes('image')) {
    return (
      <GatsbyImage
        image={getImage(node.data.target)}
        alt={node.data.target?.description ?? ''}
      />
    );
  }

  return null;
};
