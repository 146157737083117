import styled from 'styled-components';

export const Row1 = styled.div`
  flex-basis: 46px;
  flex-shrink: 0;
  height: 100%;

  display: flex;
  justify-content: center;
`;

export const RowCta = styled.div`
  flex-basis: 110px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;

  .navn-bilde {
    flex-grow: 1;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;

    padding: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gatsby-image-wrapper {
    max-height: 100px;
    max-width: 100px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;

  div {
    p {
      font-weight: bold;
    }
  }
`;

export const Grow = styled.div`
  flex-grow: 1;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;

  display: flex;
  justify-content: center;
`;
