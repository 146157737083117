import styled from '@emotion/styled';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const AStyledLink = styled.a`
  color: white;
  background: rgb(156, 39, 176, 0.5);
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  &:hover {
    background: rgba(156, 39, 176, 0.8);
  }
`;
export const AStyledPrimaryLink = styled.a`
  color: #121111;
  font-weight: bold;
  background: rgba(255, 165, 0, 1);
  padding: 16px 32px;
  border-radius: 8px;
  align-self: center;

  text-decoration: none;
  font-size: 1.5rem;
  &:hover {
    background: rgba(255, 165, 0, 0.8);
  }
`;

export const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  margin-left: 8px;
  font-size: 1.2rem;
  vertical-align: middle;
`;
