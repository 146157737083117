import React from 'react';
import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'gatsby';

export const HomeButton: React.FC = () => (
  <Button component={Link} to="/" size="large" title="Hjem">
    <HomeIcon />
  </Button>
);
