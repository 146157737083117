import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { MahButton } from '../common/MahButton';
import { ProductImage } from '../common/ProductImage';
import { Row1, RowCta, Wrapper } from './styled';

export type Product = {
  number: number;
  image: any;
  productName: string;
  affiliateUrl: string;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const SimpleItem: FC<Product> = ({
  number,
  image,
  productName,
  affiliateUrl,
}) => {
  return (
    <Wrapper>
      <Row1>
        <Typography>#{number}</Typography>
      </Row1>
      <div className="navn-bilde">
        {image && (
          <ProductImage
            alt={image.alt}
            fixed={image}
            affiliateUrl={affiliateUrl}
          />
        )}

        <Typography gutterBottom variant="h6" component="p">
          {productName}
        </Typography>
      </div>
      <RowCta>
        <MahButton url={affiliateUrl} />
      </RowCta>
    </Wrapper>
  );
};
