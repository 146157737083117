import { Typography } from '@mui/material';
import { useAffiliateProgramsContext } from '../../context/AffiliateProgramsContext';
import makeAffiliateUrl from '../../utils/affiliateUrl/makeAffiliateUrl';
import { Row1, RowCta, Header, Grow } from './styled';
import { Product, SimpleItem } from './SimpleItem';
import React, { FC } from 'react';
import { globalHistory } from '@reach/router';

const SimpleTable: FC<{ products: Array<any> }> = ({ products }) => {
  const affiliatePrograms = useAffiliateProgramsContext();
  const path = globalHistory.location.pathname;

  return (
    <>
      <Header>
        <Row1>
          <Typography>Rang</Typography>
        </Row1>
        <Grow>
          <Typography>Produktnavn</Typography>
        </Grow>
        <RowCta>
          <Typography>Butikk</Typography>
        </RowCta>
      </Header>
      {products.map((p, i) => (
        <SimpleItem
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          number={i + 1}
          image={p.productImage}
          productName={p.name}
          affiliateUrl={makeAffiliateUrl(
            p.url,
            affiliatePrograms,
            p.name,
            path,
          )}
        />
      ))}
    </>
  );
};

export default SimpleTable;
