import React, { FC } from 'react';
import styled from 'styled-components';

export const Disclosure = () => {
  return (
    <Flex>
      <Tooltip data-text="Testavisen inneholder anmeldelser, som er skrevet av våre anmeldere og skribenter i felleskap, og som er basert på anmeldernes uavhengige og profesjonelle undersøkelse av produktene/tjenestene.">
        Tester
      </Tooltip>
      <Tooltip data-text="Testavisen kan motta provisjoner når et kjøp gjøres ved hjelp av våre lenker, dette har ingen innflytelse på anmeldelsens innhold eller på de gjennomgåtte produktene/tjenestene. Vi gir direkte lenker til å kjøpe produkter som er en del av tilknyttede programmer.">
        Affiliate
      </Tooltip>
      <Tooltip data-text="Anmeldelsene som er publisert på Testavisen er skrevet av eksperter som undersøker produktene i henhold til våre strenge kriterier. Slike standarder sikrer at hver anmeldelse er basert på uavhengige, profesjonelle og ærlige undersøkelsen av anmelderen, og tar hensyn til produktets tekniske evner og kvaliteter sammen med den faktiske verdi for brukerne, noe som også kan påvirke produktets rangering på nettstedet. Dette for å gjøre anmeldelsen mest mulig objektiv.">
        Retningslinjer
      </Tooltip>
    </Flex>
  );
};

const Tooltip = styled.li`
  position: relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom: 1px dashed #000;

  :before {
    content: attr(data-text); /* here's the magic */
    position: absolute;
    margin-top: 24px;
    /* basic styles */
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    background: grey;
    color: #fff;
    text-align: center;

    display: none; /* hide by default */
  }
  :hover:before {
    display: block;
    z-index: 999999999999999;
  }
`;

const Flex = styled.ul`
  display: flex;
  /* justify-content: flex-end; */
  font-size: 12px;
  margin-top: 0;
  color: #4067da;
  cursor: pointer;

  ${Tooltip} + ${Tooltip} {
    margin-left: 24px;
  }
`;
