declare global {
  interface Window {
    _wct?: any;
  }
}

export const getWCTUrl = (affiliateUrl: string): string => {
  if (affiliateUrl.includes(blackList)) {
    return affiliateUrl;
  }
  if (
    typeof window !== 'undefined' &&
    window._wct &&
    typeof window._wct !== 'undefined' &&
    window._wct.getTrackingURL !== undefined
  ) {
    return window._wct.getTrackingURL(affiliateUrl);
  } else {
    return affiliateUrl;
  }
};

const blackList = 'kelkoo';
