/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { AStyledLink } from './Link';

export const MahButton: React.FC<{ url: string }> = ({ url }) => (
  <AStyledLink color="secondary" href={url} target="_blank" rel="nofollow">
    Kjøp nå
  </AStyledLink>
);
