import React from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '@mui/material/Button';
import { AStyledLink } from './common/Link';

const CTA = styled.div``;

const Overlay = styled.div`
  position: relative;
  align-self: center;

  ${CTA} {
    display: flex;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

type Props = {
  fixed: any;
  alt: string;
  affiliateUrl: string;
};

export const ProductImage: React.FC<Props> = ({
  fixed,
  alt,
  affiliateUrl,
}: Props) => {
  return (
    <Overlay>
      <CTA>
        <AStyledLink target="_blank" href={affiliateUrl} rel="nofollow">
          Se flere bilder
        </AStyledLink>
      </CTA>
      <GatsbyImage image={getImage(fixed)} alt={alt} />
    </Overlay>
  );
};
