import slugify from 'slugify';
import innerText from 'react-innertext';

const customIdFromJsx = (el: Element): string =>
  slugify(innerText(el), { remove: /[*+~.()'"!:@]/g }).replace(
    /[\u{0080}-\u{FFFF}]/gu,
    '',
  );

const customIdFromText = (text: string): string =>
  slugify(text, { remove: /[*+~.()'"!:@]/g }).replace(
    /[\u{0080}-\u{FFFF}]/gu,
    '',
  );

export default customIdFromJsx;
export { customIdFromText };
