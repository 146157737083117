import React, { useState } from 'react';
import { Button, MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import { Link } from 'gatsby';
import { MenuItem as MenuItemType } from './Types';

interface Props {
  menuTitle: string | JSX.Element;
  id: string;
  title: string;
  menuItems: [MenuItemType];
}

const MenuElement: React.FC<Props> = ({
  menuTitle,
  id,
  title,
  menuItems,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>();

  function handleClick(e: React.MouseEvent): void {
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  }
  function handleClose(): void {
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        title={title}
      >
        {menuTitle}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(
          ({
            shortName,
            pageReference: { slug, contentful_id },
          }: MenuItemType) => (
            <MenuItem
              key={contentful_id}
              onClick={handleClose}
              component={Link}
              to={`/${slug}/`}
            >
              {shortName}
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  );
};

export default MenuElement;
