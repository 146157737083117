/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import Typography, { TypographyProps } from '@mui/material/Typography';

const STypo = styled(Typography)`
  margin-top: 24px;
`;
interface Props {
  children: JSX.Element | string;
  id: string;
}
function CustomComponent(
  props: TypographyProps<'h2', { component: 'h2' }>,
): JSX.Element {
  return <STypo {...props}>{props.children}</STypo>;
}

export const Heading2 = ({ children, id, ...props }: Props): JSX.Element => (
  <CustomComponent variant="h5" component="h2" id={id} {...props}>
    {children}
  </CustomComponent>
);
