import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import EvolvedCard from './EvolvedCard';

type Page = {
  shortName: string;
  slug: string;
  contentful_id: string;
  featureImage: any;
  updatedAt: string;
};

type PageProps = {
  pages: [Page];
};

const PageList: React.FC<PageProps> = ({ pages }: PageProps) => {
  return (
    <Container>
      <Grid container spacing={4}>
        {pages.map(item => (
          <Grid item key={item.contentful_id} xs={12} md={6}>
            <EvolvedCard
              title={item.shortName}
              slug={`${item.slug}/`}
              image={item.featureImage}
              updated={item.updatedAt}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PageList;
