import React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components/macro';
import { Paper } from '@mui/material';
import { Heading2 } from './common/Heading2';
import { customIdFromText } from '../utils/customIdFromJsx';
import { useAffiliateProgramsContext } from '../context/AffiliateProgramsContext';
import makeAffiliateUrl from '../utils/affiliateUrl/makeAffiliateUrl';
import { PrimaryButton } from './PrimaryButton';
import { ProductImage } from './ProductImage';
import { AStyledPrimaryLink, StyledOpenInNewIcon } from './common/Link';
import { globalHistory } from '@reach/router';

const SBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  padding: 12px;

  h2 {
    margin-top: 24px;
  }

  ${PrimaryButton} {
    margin-top: 12px;
    align-self: center;
    padding: 16px;
    font-size: 24px;
  }
`;

type ContentfulProductReviewProps = {
  url: string;
  children: React.ReactElement;
  title: string;
  productImage: { file: any; description: string };
  productName: string;
};

const ContentfulProductReview: React.FC<ContentfulProductReviewProps> = ({
  children,
  url,
  title,
  productImage,
  productName,
}: ContentfulProductReviewProps) => {
  const affiliatePrograms = useAffiliateProgramsContext();
  const path = globalHistory.location.pathname;
  const actuallyAffiliateUrl = makeAffiliateUrl(
    url,
    affiliatePrograms,
    productName,
    path,
  );
  return (
    <SBox data-product as={Paper} variant="outlined">
      {productImage && productImage.file && (
        <ProductImage
          fixed={productImage}
          alt={productImage.description}
          affiliateUrl={actuallyAffiliateUrl}
        />
      )}
      <Heading2 id={customIdFromText(title)}>{title}</Heading2>
      {children}
      {url && (
        <AStyledPrimaryLink
          target="_blank"
          href={actuallyAffiliateUrl}
          rel="nofollow"
        >
          Se pris
          <StyledOpenInNewIcon />
        </AStyledPrimaryLink>
      )}
    </SBox>
  );
};

export default ContentfulProductReview;
