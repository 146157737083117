import React from 'react';
import styled from 'styled-components/macro';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { Link, navigate } from 'gatsby';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatDateString } from '../utils/date';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SCardHeading = styled(Typography)`
  text-decoration: none;
  color: black;
`;

const SCardActionArea = styled(CardActionArea)``;

const SKR = styled(ArrowForwardIcon)`
  vertical-align: middle;

  ${SCardActionArea}:hover &,
  ${SCardActionArea}:focus & {
    color: red;
  }
`;

const GoTo: React.FC<{ text: string }> = ({ text }) => (
  <>
    {text} <SKR />
  </>
);

type EvolvedCardProps = {
  title: string;
  slug: string;
  image: any;
  updated: string;
};

const EvolvedCard: React.FC<EvolvedCardProps> = ({
  title,
  slug,
  image,
  updated,
}: EvolvedCardProps) => {
  const linkSlug = `/${slug}`;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onActionAreaClick = () => {
    navigate(linkSlug);
  };

  return (
    <SCardActionArea onClick={onActionAreaClick}>
      <Card elevation={0}>
        <CardContent>
          <SCardHeading variant="h5" tabIndex={-1}>
            {title}
          </SCardHeading>
          {/* <Typography variant="subtitle1" component="p" color="textSecondary">
            Oppdatert: {formatDateString(updated)}
          </Typography> */}
          {image && <GatsbyImage image={getImage(image)} alt={''} />}
          <Typography variant="subtitle1" component="p" color="primary">
            <GoTo text="Til testen" />
          </Typography>
        </CardContent>
      </Card>
    </SCardActionArea>
  );
};

export default EvolvedCard;
