import React from 'react';
import Box from '@mui/material/Box';
import MenuElement from './MenuElement';
import { HomeButton } from './HomeButton';
import Paper from '@mui/material/Paper';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';

const EvolvedMenu: React.FC<{ menuItems: any }> = ({ menuItems }) => {
  return (
    <Paper elevation={0} square>
      <Box
        display="flex"
        justifyContent="space-evenly"
        p="12px"
        component="nav"
      >
        <HomeButton />
        {menuItems.length === 0 ? (
          <></>
        ) : (
          <MenuElement
            menuTitle={<MenuBookRoundedIcon />}
            id="simple-menu"
            title="Artikler"
            menuItems={menuItems}
          />
        )}
      </Box>
    </Paper>
  );
};

export default React.memo(EvolvedMenu);
